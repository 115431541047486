:root{

    --second-logo-height:43vw;
    --second-logo-width: 23vw;
}
.rpex-header {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    justify-content: center;
    background-image: 
    linear-gradient(rgb(0,0,0, 0.5), rgb(0,0,0, 0.5)),
    url('../../Assets/RPEX/rpex_header.JPG');
    background-position: 50% 74%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20em;
    max-height: 20em;
    gap: 1.5em;
    h1 {

        color: white;
        font-size: 4em;
        padding:20px;
    }
    h2 {

        color: white;
        font-weight: 400;
        font-style: italic;
        font-size: 2em;
        text-align: center;
        padding-left:30px;
        padding-right:30px;

    }

}


.rpex-about {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.35em;
    font-size:1.2em;
    h1{
        font-family: 'Lexend';
        padding:30px;

    }
    .color_header{
        width:100%;
    }

}

.rpex-text {
    display:flex;
    flex-direction: column;
    font-size: 1.8em;
    text-align: left;
    padding-left:20%;
    padding-right:5%;
    padding-bottom:5%;
    gap: 10px;
    p{
        font-family: 'Lexend';

    }
}


.rpex-logo {
    font-family: 'Lexend';

    height: 23vw;
    width: 23vw;
    transform: translate(0,-10%);
    padding-left:15%;
    padding-bottom:1%;
    display: flex;
    position:relative;
    justify-content: center;
    z-index:2;
    float:left;

  }

.rpex-logo2{
    display:flex;
        height: 0vw;
    width: 0vw;
    justify-content: center;
    z-index:2;

}




.gallery_space{
    position: relative; /* Add position relative to create a positioning context */
    padding-bottom: 4em;
    font-size: 1.2em;
    text-align: center;
    justify-content: center;
    background-color: #FCFBFB;
    z-index:0;
    h1{
        font-family: 'Lexend';

    }

}
.beeWax{
    position: absolute; 
    z-index: 0; 
    transform: translate(29%,16%);
    right: 0;
    width:700px;

}
.beeWax2{
    position: absolute; 
    transform: rotate(-10deg);
    transform: translate(-320px,70px);
    z-index: 1; 
    width:40vw;
}
.rpex_gallery{
    padding-top:1.5em;
    z-index:2;
    display:flex;
    position:relative;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.dot {
    width: 17px;
    height: 17px;
    margin: 0 15px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .active-rpex {
    width: 19px;
    height: 19px;
  }
  
  .carousel-container {
    width: 100%;
    transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
    max-width: 1250px; /* Adjust the desired maximum width */
    margin: 5% auto; /* Center the carousel horizontally */
    padding-left:2%;
    
  }
  
  .carousel-item {
    display: flex;
  justify-content: center;
  align-items: center;
  width: 35rem; /* Adjust the desired width */
  height: 23rem; /* Adjust the desired height */
  border-radius: 0.5em;
  user-select: none;

    
  }
  
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    z-index:1;
    width: auto;
    position: relative; 
    gap: 9vw;
    
  }



  .cards .red .info-card p {
    color: black;
    display: flex;
    font-size:17.5px;
    text-align:center;
    


  }
  
  .cards .red .info-card h1 {
    color: black;
    display: flex;
    font-size:26px;
    padding-left:2em;
    padding-right:1.5em;
    padding-top:.5em;

  }
  
  .cards .red .info-card {
    flex: 1;
    width: auto;
    max-width: 29em;
  }

  .cards .first_card .info-card p {
    font-size:17px;

    }

body{

    overflow-x: hidden;
  }

  .rpex-video{
    padding-top: 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: 'Lexend';

        padding-top: 1em;
        font-size:2em;

    }

    hr {
        margin: 0 auto;
        margin-top: 1em;
        border-color: black;
        width: 16em;
    }
    .video-section {
        padding-bottom: 2.5em;

        video {
            margin-top: 3em;
            height: 35vw;
            border-radius: 15px;
        }

    }
  }

  .banner-btn1 {
    margin:40px auto 0;
    position:absolute;

}

.banner-btn1 a{
    font-family: 'lexend';
    text-align: center;
    width:560px;
    text-decoration: none;
    display: inline-block;
    padding:12px 0;
    background: #1262AB;

    color: rgb(255, 255, 255);
    border-radius: 20px;
    position: relative;
    transition: color 0.5s;
    overflow: hidden;
    transition: transform 0.3s ease-out;

}
.banner-btn1{
    transform: translate(350px,480px);
    z-index:4;
    max-width:560px;


}

.btn1:hover{
    transform:scale(1.1);
}
.banner-btn2 {
    margin:40px auto 0;
    position:absolute;
    width:560px;

}

.btn2{
    font-family: 'lexend';
    text-align: center;
    width:560px;
    text-decoration: none;
    display: inline-block;
    padding:12px 50px;
    background: #ED1C24;
    border:0;
    color: rgb(255, 255, 255);
    border-radius: 20px;
    position: relative;
    transition: color 0.5s;
    overflow: hidden;
    transition: transform 0.3s ease-out;
    font-size:20px;
    z-index:2;


}

.banner-btn2{
    transform: translate(-350px,480px);

}

.btn2:hover{
    transform:scale(1.1);
}
@media (min-width: 1800px) and (max-width: 2560px) {
    .rpex-logo {
        
        height: 15vw;
        width: 15vw;
        transform: translate(0,-20%);
    }
    .beeWax2{
        transform: translateX(-250px);
        width:30vw;
    }
    .cards{
        gap:7em;
    }
    .rpex_gallery{
        transform: translateX(-200px);

    }
}

@media (min-width: 1301px) and (max-width: 1800px) {
    .rpex-logo {
        
        height: 20vw;
        width: 20vw;
        transform: translate(0,-10%);
    }
}
  @media (max-width: 1300px) {
    .rpex-logo {
        display: none;
        float:none;
     
    }
    .carousel-item {
        margin-left: auto; /* Horizontally center the item */
        margin-right: auto;

      /* Center the image horizontally */
    }
    .rpex-text p{
        display: flex;
      flex-wrap: wrap;
      padding-right: 15%;
    }
    .rpex-logo2{
        display:flex;
        height: var(--second-logo-height);
        width: var(--second-logo-width);
    justify-content: center;
    padding-left:50%;
    padding-bottom:2%;

    
    }

    .cards{
        flex-wrap: wrap;
        padding-top:2%;
        gap: 9em;
    }

    .rpex-video {
        .video-section {
            video {
                height: 50vw;
            }
        }
    }
    .beeWax2{
        transform: translate(-250px,550px);
        width:50vw;

    }
    
    .beeWax{
        transform: translate(40%,130%);
    }
    
  }

  @media (max-width: 1290px) {
    .banner-btn2{
        transform: translate(0px,480px);
    }
    .banner-btn1{
        transform: translate(0px,1115px);

    }
    
    
    
  }
  @media (max-width: 980px) {
    .rpex-text{
        font-size: 1.7em;
     
    }

    .cards{
        padding-top:8%;
        gap: 15vw;

    }
    
    .beeWax2{
        transform: translate(-200px,440px);

        width:50vw;
    }   
    .banner-btn1{
        transform: translate(0px,1100px);

    }
    .beeWax{
        transform: translate(50%,110%);
    }
    
}

    @media (max-width: 800px) {
        .rpex-text{
            font-size: 1.5em;
         
        }
        
        
        .cards{
            padding-top:8%;
            gap: 15vw;

        }
        .beeWax2{
            transform: translate(-160px,440px);
            width:50vw;
    
        }
        .banner-btn1{
            transform: translate(0px,1090px);
    
        }
        
  }
  
  @media (max-width: 600px) {
    .rpex-text{
        font-size: 1.25em;
     
    }
    
    
    .cards{
        padding-top:8%;
        gap: 20vw;

    }
    .carousel-item {
        width: 27rem; /* Adjust the desired width */
        height: 16.3rem; /* Adjust the desired height */
        padding-right:2%;
    }
    
    .cards .info-card h1{
        font-size:25px;
        
    }
    .banner-btn1{
        transform: translate(0px,1050px);

    }
    .beeWax2{
        transform: translate(-150px,420px);
        width:60vw;

    }
    .beeWax{
        transform: translate(70%,120%);
    }
    .banner-btn1 a{
        width:400px;
    }
    .btn2{
        width:400px;
    }

}


@media (max-width: 500px) {
    .rpex-text{
        font-size: 1em;
     
    }
    .rpex_gallery{
        font-size:30px;
        z-index:2;
    }
    
    .rpex-logo2{
        z-index:-1;
    }
    .carousel-item {
        width: 82vw; /* Adjust the desired width */
        height: 53vw; /* Adjust the desired height */
        padding-right:2%;
    }
    .dot {
        width: 10px;
        height: 10px;
    }
    .cards{
        padding-top:3%;
        gap: 30vw;


    }
    .beeWax2{
        transform: translate(-120px,330px);
        width:50vw;

    }
    .cards .red .info-card p {
        font-size:14px;
      }
    .cards .first_card .info-card p {
        font-size:13px;
      }
      
      .cards .red .info-card{
        max-width: 22rem;
    
      }
      .banner-btn1{
        transform: translate(0px,1080px);
    }
    .banner-btn1 a{
        width:300px;
    }
    .btn2{
        width:300px;
    }

 
}
