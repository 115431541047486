$circle-size: 7em;
$card-height: 25em;
$card-width: 30em;

.info-card {

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);

    border-radius: 30px;

    padding-bottom: 3em;
    
    width: $card-width;
    height: $card-height;




    h1 {
        font-family: 'Lexend';
        margin-top: 0.5em;
        font-size: 28px;
        padding-top:2%;
    }

    p {
        font-family: 'Lexend';

        display: flex;
        padding: 1em 2em;
        margin-top: 0.5em;
        font-size: 16px;
        text-align: center;
    }


}

@media only screen and (max-width: 1400px) {
    .info-card {
        p { 
            font-size: 15px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .info-card {
        h1 {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .info-card {
        p {
            font-size: 13px;
        }
    }
}
