.FLL-header {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    justify-content: center;
    background-image: 
    linear-gradient(rgb(0,0,0, 0.5), rgb(0,0,0, 0.5)),
    url('../../Assets/FLL/Competitions37.jpg');
    background-position: 50% 35%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20em;
    max-height: 20em;
    gap: 1.5em;
    padding: 5vw 0em;
    h1 {
        color: white;
        font-size: 4em;
    }
    h2 {
        color: white;
        font-weight: 400;
        font-style: italic;
        font-size: 1.15em;
    }
}
.FLL-content{
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top:2.5em;
    hr {
        margin: 0 auto;
        margin-top: 1em;
        border-color: black;
        width: 10em;
        margin-bottom: 1em;
    }
    .about{
        padding-left:5em;
        padding-right:5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0.4em;
        overflow: hidden;
        h2{
            overflow-wrap: break-word;
            text-align: center;
            max-width: 95%;
            font-weight: 400;
            font-size: 1.25em;
            margin-bottom: 2em;
        }
        a {
            width:fit-content;
            margin: 3.5em 0em;
            margin-bottom: 2em;
            font-size: 1.5em;
            border: 1px solid black;
            padding: 20px;
            transition: all .25s;
            color:black;
            &:hover {
                transition: all .25s;
                background-color: rgb(255, 230, 0);
            }
            text-decoration: none;
        }
    }
    .outreach{
        display: flex;
        flex-direction: column;
        padding: 4em;
        align-items: center;
        text-align: center;
        background-color: rgb(255, 230, 0);
        overflow: hidden;
        h2{
            overflow-wrap: break-word;
            margin-top: 2em;
            max-width: 100%;
            font-weight: 400;
            font-size: 1.25em;
        }
        .row{
            display: flex;
            flex-direction: row;
            gap: 5em;
            margin-top: 5em;
            text-align: left;
        }
        .rtext{
            display: flex;
            flex-direction: row;
            gap: 5em;
            margin-top: 5em;
            text-align: right;
        }
    }
    .modulink{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2.5em;
        margin-bottom: 2.5em;
        overflow: hidden;
        .row{
            display: flex;
            flex-direction: row;
            padding-left: 5em;
            padding-right: 5em;
            margin-top: 2em;
            margin-bottom: 2em;
            h2{
                overflow-wrap: break-word;
                text-align: center;
                max-width: 95%;
                font-weight: 400;
                font-size: 1.25em;
                margin-bottom: 2em;
                text-align: left;
                margin-top: 2em;
            }
        }
        .marquee{
            img{
                border-radius: 12px;
                margin: 1em;
                height: 15vw;
                opacity: 80%;
            }
        }
        .exampleGame{
            background-color: black;
            width: fit-content;
            height: fit-content;
            border-radius: 150px;
            color: white;
            font-size: 1.5em;
            padding: 1em;
            transition: all .25s;
            margin-top: 1em;
            margin-bottom: 1em;
            &:hover{
                transition: all .25s;
                padding: 1.3em;
                background-color: rgb(255, 230, 0);
                border-color: solid black;
                color: black;
            }
        }
    }
    .teamhistory {
        padding-left: 5em;
        padding-top: 2.5em;
        padding-right: 5em;
        padding-bottom: 2.5em;
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 230, 0);
        h1 {
            text-align: center;
        }
    
        .row {
            margin-top: 2em;
            display: flex;
            flex-direction: row;
            gap: 3.3em;
    
            .history {
                display: flex;
                flex-direction: column;
                text-align: center;
    
                ul {
                    margin-bottom: 1em;
                    list-style-type: none;
                }
    
                li {
                    font-size: 1.05em;
                    margin: 0.2em;
                }
    
                h3 {
                    margin-top: 2em;
                    font-weight: 400;
                }
            }
    
            .container {
                border: 0.1em solid black;
                justify-content: center;
                border-radius: 12px;
                align-items: center;
                display: block;
                width: 25em;
    
                .col {
                    margin-left: auto;
                    margin-right: auto;
                    position: sticky;
                    align-items: center;
                    text-align: center;
                    margin: 0.75em;
                    top: 50%;
                    bottom: 50%;
                    border-radius: 12px;
                    border-width: 0.1em;
                    border-color: solid black;
                    overflow: hidden;
    
                    img {
                        width: 100%;
                        max-height: 20%;
                        border-radius:  12px;
                        opacity: 0;
                        position: absolute;

                        &.active {
                            position: relative;
                            opacity: 1;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }
            }
        }
    }
    .mentors{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        font-size: large;
        padding-top: 2.5em;
        padding-bottom: 2.5em;
        padding-left: 5em;
        padding-right: 5em;
        overflow: hidden;
        .row{
            display: flex;
            flex-direction: row;
            justify-content: center;
            .lcol{
                text-align: left;
                width: 40%;
                border-right: solid black;
                border-width: 0.01em;
            }
            .rcol{
                text-align: right;
                width: 40%;
            }
            p{
                margin-top: 2em;
            }
        }
    }
    }

    @media (max-width: 1240px){
        .FLL-content{
            .outreach{
                .row{
                    flex-direction: column;
                    text-align: center;
                }
                .rtext{
                    flex-direction: column-reverse;
                    text-align: center;
                }
            }
            .modulink{
                .row{
                    flex-direction: column;
                    text-align: center;
                    h2{
                        text-align: center;
                    }
                }
            }
        }
    }

    @media (max-width: 1000px){
        .FLL-content{
            .mentors{
                .row{
                    .lcol{
                        width: 50%;
                        padding: 0.7em;
                    }
                    .rcol{
                        width: 50%;
                        padding: 0.7em;
                    }
                }
            }
        }
    }
    @media (max-width: 980px){

    }
    @media (max-width: 900px){
        .FLL-content{
            .mentors{
                .row{
                    flex-direction: column;
                    .lcol{
                        border-right: transparent;
                        width: 100%;
                        text-align: center;
                    }
                    .rcol{
                        width: 100%;
                        text-align: center;
                        margin-top: 2em;
                    }
                    p{
                        margin-top: 0.5em;
                    }
                }
            }
        }
    }

    @media (max-width: 850px){
        .FLL-content{
            .teamhistory{
                .row{
                    .container{
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: 800px){
        .FLL-content{
            .ImageSlider {
                img {
                    height: 50vw;
                    width: 70vw;
                }
                svg {
                    height: 3vh;
                    width: 3vh;
                }
            }
        }
    }

    @media (max-width: 1240px){
        .FLL-content{
            .outreach{
                .row{
                    flex-direction: column;
                    text-align: center;
                }
                .rtext{
                    flex-direction: column-reverse;
                    text-align: center;
                }
            }
            .modulink{
                .row{
                    flex-direction: column;
                    text-align: center;
                    h2{
                        text-align: center;
                    }
                }
            }
        }
    }

    @media (max-width: 1000px){
        .FLL-content{
            .mentors{
                .row{
                    .lcol{
                        width: 50%;
                        padding: 0.7em;
                    }
                    .rcol{
                        width: 50%;
                        padding: 0.7em;
                    }
                }
            }
        }
    }

    @media (max-width: 900px){
        .FLL-content{
            .mentors{
                .row{
                    flex-direction: column;
                    .lcol{
                        border-right: transparent;
                        width: 100%;
                        text-align: center;
                    }
                    .rcol{
                        width: 100%;
                        text-align: center;
                        margin-top: 2em;
                    }
                    p{
                        margin-top: 0.5em;
                    }
                }
            }
        }
    }

    @media (max-width: 850px){
        .FLL-content{
            .teamhistory{
                .row{
                    .container{
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: 800px){
        .FLL-content{
            .ImageSlider {
                img {
                    height: 50vw;
                    width: 70vw;
                }
                svg {
                    height: 3vh;
                    width: 3vh;
                }
            }
    }
}