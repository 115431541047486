.lockout {
    display: flex;
    justify-content: center;
    padding: 18vh 5vw;
    text-align: center;
    .red {
        color: rgb(209, 0, 0);
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.541);
        gap: 2em;
        padding: 2em;
        border-radius: 10px;
    }
    a {
        border: 1px solid black;
        padding: 10px;
        text-decoration: none;
        color: black;
        transition: all .25s;
    }
    a:hover {
        background-color: rgba(255, 208, 0, 0.836);
        transition: all .25s;
    }
    hr {
        width: 100%;;
        margin-bottom: 10px;
    }
    img {
        height: 60px;
        width: 60px;
    }
}
