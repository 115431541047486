.Footer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(46, 46, 46);
    color: white;
    padding: 15px 50px;
    h1 {
        font-size: 1em;
    }
    h2 {
        font-size: 0.75em;
        color: rgb(255, 230, 0);
    }
    .row {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
    }
    img {
        height: 25px;
    }
}

@media only screen and (max-width: 550px) {
    .Footer {
        img {
            height: 5vw;
        }
        h1 {
            font-size: 0.75em;
        }
    }
}

@media only screen and (max-width: 400px) {
    .Footer {
        padding: 15px 25px;
        .row {
            h1 {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 675px) {
    .Footer {
        padding: 15px 25px;
        .row {
            h1 {
                display: none;
            }
        }
    }
}

