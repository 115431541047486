html{
    scroll-behavior: smooth;
}

.IK-header {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    justify-content: center;
    background-image: 
    linear-gradient(rgb(0,0,0, 0.5), rgb(0,0,0, 0.5)),
    url('../../Assets/SIAB/Header.png');
    background-position: 50% 55%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20em;
    max-height: 20em;
    gap: 1.5em;
    padding: 5vw 0em;
    h1 {
        color: white;
        font-size: 4em;
    }
    h2 {
        color: white;
        font-weight: 400;
        font-style: italic;
        font-size: 1.15em;
    }
}
.IK-content{
    display: flex;
    flex-direction: column;
    align-content: center;
    hr {
        margin: 0 auto;
        margin-top: 1em;
        border-color: black;
        width: 10em;
        margin-bottom: 2em;
    }
    .announcementcontainer{
        display: flex;
        padding-top: 2.5em;
        background-color: black;
        width: 100%;
        align-items: center;
        .announcement{
            width: 90%;
            text-align: center;
            margin-bottom: 2em;
            margin-left: 5em;
            margin-right: 5em;
            border: 1px solid black;
            padding:1em;
            border-radius: 30px;
            background-color: rgb(255, 230, 0);
        }
    }
    .sponsors{
        hr {
            border-color: white;
        }
        display: flex;
        flex-direction: column;                                                   
        padding-left: 5em;
        padding-right: 5em;
        align-items: center;
        background-color: black;
        color: white;
        text-align: center;
        padding-bottom: 2.5em;
        overflow: hidden;
        height: 30vw;
        .sponsor-card{
            margin: 1em;
        }
        .sponsorrow{
            display: flex;
            flex-direction: row;
        }
        .honeyComb1{
            transform: translate(100%,-47%);
            z-index: 4;
            margin-bottom:-30em;
            scale: 1.3;
        }
        .honeyComb2{
            transform: translate(-100%,-80%);
            z-index: 4;
            margin-bottom:-30em;
            scale: 1.3;
        }
    }
    .about{
        margin-left:5em;
        margin-right:5em;
        margin-top: 2.5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2.5em;
        h2{
            text-align: center;
            overflow-wrap: break-word;
            max-width: 80%;
            font-weight: 400;
            font-size: 1.25em;
        }
        .row{
            display: flex;
            flex-direction: row;
            text-align: center;           
        }
        a {
            width:fit-content;
            margin: 2em 0em;
            margin-bottom: 2em;
            font-size: 1.5em;
            border: 1px solid black;
            padding: 20px;
            transition: all .25s;
            color:black;
            &:hover {
                transition: all .25s;
                background-color: rgb(255, 230, 0);
            }
            text-decoration: none;
        }
    }
    .exampleKit{
        text-align: center;
        padding-top: 2.5em;
        padding-bottom: 2.5em;
        background-color: rgb(255, 230, 0);
        padding-left: 5em;
        padding-right: 5em;
        p{
            margin-bottom: 2em;
            font-style: italic;
        }
        img{
            border-radius: 12px;
            max-width: 80%;
            max-height: 30vw;
        }
        .instructionsButton{
            margin-top:2.5em;
            background-color: rgb(0,0,0);
            color: white;
            border-radius:12px;
            padding-top: 1em;
            border:0.1em solid black;
            padding-bottom: 1em;
            width: 33%;
            font-weight: 400;
            font-size: larger;
            transition: all .25s;
            &:hover{
                transition: all .25s;
                background-color: rgb(255, 230, 0);
                border-color: 0.1em solid black;
                color: black;
            }
        }
    }
    .moreInfo{
        margin-left:5em;
        margin-right:5em;
        margin-top:2.5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2.5em;
        .row{
            margin-top: 7em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 3.3em;
            .container{
                border: 0.1em solid black;
                justify-content: center;
                .center{
                    margin-top: 2em;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    position: sticky;
                    align-items: center;
                    text-align: center;
                    top: 50%;
                    bottom: 50%;
                    color: black;
                    background-color: white;
                    border-radius: 12px;
                    border-width: 0.1em;
                    border-color: solid black;
                    a{
                        width: 100%;
                        height: 100%;
                        color: black;
                        text-decoration: none;
                        .faqButton{
                            width: fit-content;
                            margin: 1em 0em;
                            margin-bottom: 2em;
                            font-size: 1.5em;
                            border: 1px solid black;
                            padding: 20px;
                            transition: all .25s;
                            color: black;
                            background-color: white;
                            &:hover {
                                transition: all .25s;
                                background-color: rgb(255, 230, 0);
                            }
                            text-decoration: none;   
                        }
                    }
                }
            }
        }
    }
    .faq{
        display: flex;
        flex-direction: column;
        background-color: white;
        align-items: center;
        padding-top: 2.5em;
        padding-left: 5em;
        padding-right: 5em;
        padding-bottom: 2.5em;
        .desc {
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            margin-bottom: 2em;
        }
        .accordion {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: rgb(255, 230, 0);
            border: 1px solid black;
            border-radius: 12px;
            width: 85vw;
            font-size: 1.25em;
            color: black;
            text-align: left;
            padding: 0.5em 2em;
            transition: 0.5s;
            svg {
                pointer-events: none;
            }
        }
    
        .active, .accordion:hover {
            background-color: rgb(194, 174, 0);
        }
    
        .panel {
            text-align: left;
            max-height: 0;
            margin-top: 1em;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            margin-bottom: 1em;
            width: 80vw;
            a{
                font-weight: bold;
            }
        }
    }
}
@media (max-width: 1450px) {
    .IK-content{
        .sponsors{
            hr{
                margin-bottom: 1em;
            }
            .sponsor-card{
                scale: 0.9;
            }
        }
        .about {
            padding: 7.5vh 5vw;
        }
    }
}

@media (max-width: 1390px){
    .IK-content{
        .sponsors{
            hr{
                margin-bottom: 0em;
            }
            .sponsor-card{
                scale: 0.77;
            }
            .honeyComb1{
                transform: translate(90%,-47%);
            }
            .honeyComb2{
                transform: translate(-90%, -80%);
            }
        }
    }
}

@media (max-width: 1300px){
    .IK-content{
        .about {
            margin-left: 2em;
            margin-right: 2em;
            .row{
                gap: 2em;
                .ImageSlider {
                    img {
                        height: 25vw;
                        width: 35vw;
                    }
                    svg {
                        height: 3vh;
                        width: 3vh;
                    }
                }
            }
        }
    }
}
@media (max-width: 1260px){
    .IK-content{
        .sponsors{
            .honeyComb1{
                transform: translate(90%,-57%);
                scale: 1.15;
            }
            .honeyComb2{
                transform: translate(-91%, -90%);
                scale: 1.15;
            }
        }
    }
}

@media (max-width: 1200px){
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.7;
                margin-top: -1em;
            }
        }
    }
}

@media (max-width: 1065px){
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.65;
                margin-top: -2em;
            }
        }
        .moreInfo{
            .row{
                display: flex;
                flex-direction: column;
            }
            .product-card{
                margin-bottom: 5em;
            }
            .container{
                display: none;
            }
        }
    }
}

@media (max-width: 980px){
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.6;
                margin-top: -3em;
            }
            .honeyComb1{
                transform: translate(80%,-57%);
                scale: 1.15;
            }
            .honeyComb2{
                transform: translate(-81%, -90%);
                scale: 1.15;
            }
        }
        .about {
            h1{
                text-align: center;
            }
            .row{
                display: flex;
                flex-direction: column;
                .column{
                    display: flex;
                    flex-direction: column;               
                    h2{
                        text-align: center;
                        max-width: 900px;
                    }
                }
                .ImageSlider {
                    img {
                        height: 50vw;
                        width: 70vw;
                    }
                    svg {
                        height: 3vh;
                        width: 3vh;
                    }
                }
            }
            a {
                text-align: center;
                margin: auto;
                margin-top: 5vh;
            }
        }
    }
}


@media (max-width: 885px) {
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.5;
                margin-top: -4em;
            }
            .honeyComb1{
                transform: translate(90%,-77%);
                scale: 0.9;
            }
            .honeyComb2{
                transform: translate(-91%, -110%);
                scale: 0.9;
            }
        }
        .exampleKit {
            display: flex;
            flex-direction: column;
            align-items: center;
            .ImageSlider {
                img {
                    height: 50vw;
                    width: 70vw;
                }
                svg {
                    height: 3vh;
                    width: 3vh;
                }
            }
        }
    }
}

@media (max-width: 800px){
    .IK-content{
        .sponsors{
            height: 40vw;
            .sponsor-card{
                scale: 0.7;
                margin-top: -2em;
            }
            .honeyComb1{
                transform: translate(85%,-77%);
                scale: 0.9;
            }
            .honeyComb2{
                transform: translate(-86%, -110%);
                scale: 0.9;
            }
        }
    }
}

@media (max-width: 740px){
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.62;
                margin-top: -3em;
            }
            .honeyComb1{
                transform: translate(80%,-77%);
                scale: 0.9;
            }
            .honeyComb2{
                transform: translate(-81%, -110%);
                scale: 0.9;
            }
        }
    }
}

@media (max-width:  680px) {
    .IK-content{
            .sponsors{
                .sponsor-card{
                    scale: 0.5;
                    margin-top: -3.5em;
                }
                .honeyComb1{
                    transform: translate(80%,-77%);
                    scale: 0.9;
                }
                .honeyComb2{
                    transform: translate(-71%, -120%);
                    scale: 0.9;
                }
            }
        .moreInfo {
            h1 {
                    font-size: 1.5em;
            }
            }
        .faq {
            padding: 3vh 5vw;
        }
    }
}

@media (max-width:  600px) {
    .IK-content{
        .sponsors{
            height: 50vw;
            .sponsor-card{
                scale: 0.6;
                margin-top: -2em;
            }
            .honeyComb1{
                transform: translate(80%,-77%);
                scale: 0.9;
            }
            .honeyComb2{
                transform: translate(-71%, -120%);
                scale: 0.9;
            }
        }
        .about {
            padding: 5vh 10vw; 
        }
        .exampleKit{
            .instructionsButton{
                padding-left: 1vh;
                padding-right: 1vh;
                width: fit-content;
            }
        }
    }
}

@media (max-width: 565px){
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.45;
                margin-top: -4.5em;
            }
            .honeyComb1{
                display: none;
            }
            .honeyComb2{
                display: none;
            }
        }
    }
}

@media (max-width: 460px){
    .IK-content{
        .sponsors{
            height: 60vw;
            .sponsor-card{
                scale: 0.4;
                margin-top: -4.5em;
            }
        }
    }
}

@media (max-width: 412px){
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.35;
                margin-top: -5em;
            }
        }
    }
}

@media (max-width:  400px) {
    .IK-header {
        h1 {
            font-size: 3em
        }
        h2 {
            font-size: 1em;
        }
    }
    .IK-content{
        .sponsors{
            height: 70vw;
            .sponsor-card{
                scale: 0.4;
                margin-top: -5em;
            }
        }
        .about {
            h2 {
                font-size: 0.9em;
            }
        }
        .moreInfo {
            h1 {
                font-size: 1em;
            }
            .product-card{
                max-width: 345px;
            }
        }
        .faq {
            .desc  {
                font-size: 16px;
            }
            .accordion {
                font-size: 14px;
            }
            .panel {
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 345px){
    .IK-content{
        .sponsors{
            .sponsor-card{
                scale: 0.35;
                margin-top: -5.5em;
            }
        }
        .moreInfo{
            h1{
                text-align: center;
                max-width: fit-content;
            }
            .product-card{
                max-width: 300px;
            }
        }
    }
}