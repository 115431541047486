.home-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    padding-top: 150px;
    height: 90vh;
    gap: 4em;
    text-align: center;
    background-image: linear-gradient(rgb(0,0,0, 0.7), rgb(0,0,0, 0.7)), url('../../Assets/Home/Background.jpg');
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .Typewriter {
        font-size: 3em;
        color: rgb(255, 196, 0);
        font-weight: 700;
    }
    a { 
        color: white;
        text-decoration: none;
        font-size: 1.5em;
        border: 2px solid white;
        padding: 10px;
        border-radius: 5px;
        &:hover {
            transition: all .25s;
            background-color: white;
            color: black;
        }
        transition: all .25s;
    }
    h1 {
        color: white;
        font-size: 6em;
    }
}

.home-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    padding-top: 50px;
    background-color: rgb(255, 196, 0);
    color: black;
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 100px;
        padding-right: 100px;
        gap: 1em;
    }
    .column {
        display: flex;
        flex-direction: column;
        align-content: center;
    }
    p {
        max-width: 90%;
        font-size: 1.5em;
        padding-bottom: 2em;;
    }
    img {
        height: 350px;
    }
    hr {
        margin: 0 auto;
        margin-top: 1em;
        border-color: black;
        width: 10em;
        margin-bottom: 3em;
    }
    a {
        margin: 3.5em 0em;
        margin-bottom: 2em;
        font-size: 1.5em;
        border: 1px solid black;
        padding: 20px;
        transition: all .25s;
        color:black;
        &:hover {
            transition: all .25s;
            background-color: white;
        }
        text-decoration: none;;
    }
    .mobile {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.home-three {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    text-align: center;
    hr {
        margin: 0 auto;
        margin-top: 1em;
        border-color: black;
        width: 10em;
        margin-bottom: 2em;
    }
    .flex-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2em;
        .row {
            display: flex;
            flex-direction: row;
        }
        .col {
            display: flex;
            flex-direction: row;
            flex: 1;
        }
        .sponsor-card {
            margin: 2vw 3vw;
        }
    }
    .mobile1  {
        display: none;
    }
    .mobile2  {
        display: none;
    }
    .mobile3  {
        display: none;
    }
    h2 {
        margin: 1em 0;
    }
    .advisors{
        font-size: larger;
    }
}

@media only screen and (max-width: 1650px) {
    .home-two {
        .mobile {
            display: flex;
        }
        .computer {
            display: none;
        }
    }
}

@media only screen and (max-width: 950px) {
    .home-one {
        h1 {
            font-size: 5em;
        }
    }
    .home-two {
        p {
            font-size: 1.25em;
        }
        a {
            font-size: 1.25em;
        }
        .ImageSlider {
            img {
                height: 325px;
                width: 500px;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .home-two {
        p {
            font-size: 1em;
        }
        a {
            font-size: 1em;
        }
        .ImageSlider {
            
            img {
                height: 50vw;
                width: 72.5vw;
            }
            svg {
                height: 5vw;
                width: 5vw;
            }
        }
    }
}

@media only screen and (max-width: 670px) {
    .home-one {
        h1 {
            font-size: 4em;
        }
    }
}

@media only screen and (max-width: 460px) {
    .home-one {
        padding-top: 15vh;
        gap: 3em;
        h1 {
            font-size: 3.5em;
        }
        .Typewriter {
            font-size: 2em;
        }
    }
}

@media only screen and (max-width: 390px) {
    .home-one {
        padding-top: 15vh;
        gap: 6em;
        h1 {
            font-size: 2.5em;
        }
        .Typewriter {
            font-size: 2em;
        }
    }
    .home-two {
        hr {
            margin-bottom: 1em;
        }
    }
}

// GRID

@media only screen and (max-width: 1700px) {
    .home-three {
        .web {
            display: none;
        }
        .mobile1 {
            display: flex;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .home-three {
        .web {
            display: none;
        }
        .mobile1 {
            display: none;
        }
        .mobile2 {
            display: flex;
        }
    }
}

@media only screen and (max-width: 750px) {
    .home-three {
        .web {
            display: none;
        }
        .mobile1 {
            display: none;
        }
        .mobile2 {
            display: none;
        }
        .mobile3 {
            display: flex;
        }
    }
}